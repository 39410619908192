export default class PageSwitch {
  constructor(next, previous, currentPage = 1) {
    this.currentPage = Number(currentPage);
    this.nextBtn = next;
    this.previousBtn = previous;
    this.pages = [1, 2, 3];
  }

  next() {
    const oldNumber = this.currentPage;
    const newNumber = this.increment();

    this.findPage(oldNumber).style.display = 'none';
    this.findPage(newNumber).style.display = 'block';
    this.afterChangePage();
  }

  previous() {
    const oldNumber = this.currentPage;
    const newNumber = this.decrement();

    this.findPage(oldNumber).style.display = 'none';
    this.findPage(newNumber).style.display = 'block';
    this.afterChangePage();
  }

  findPage(pageNumber) {
    return document.querySelector(`[data-page="${pageNumber}"]`);
  }

  increment() {
    if (this.pages.includes(this.currentPage + 1)) {
      return this.currentPage += 1;
    } else {
      return 3;
    }
  }

  decrement() {
    if (this.pages.includes(this.currentPage - 1)) {
      return this.currentPage -= 1;
    } else {
      return 1;
    }
  }

  afterChangePage() {
    [this.previousBtn, this.nextBtn].forEach((btn) => {
      btn.removeAttribute('disabled');
    });

    if (this.currentPage == this.pages[2]) {
      this.nextBtn.setAttribute('disabled', 'true');
    } else if (this.currentPage == this.pages[0]) {
      this.previousBtn.setAttribute('disabled', 'true')
    }
  }
}
