export default () => {
  const form = document.querySelector('#claimsForm');
  const nextBtn = document.querySelector('#next');
  const prevBtn = document.querySelector('#prev');

  const pages = Array.from(document.querySelectorAll('[data-page]'));

  if (form && nextBtn && prevBtn) {
    form.addEventListener('invalid', () => {
      nextBtn.style.display = 'none';
      prevBtn.style.display = 'none';

      pages.forEach((page) => {
        page.style.display = 'block';
      })
    }, true);
  }
}
