import PageSwitch from './PageSwitch';

export default () => {
  const next = document.querySelector('#next');
  const prev = document.querySelector('#prev');
  const pageSwitch = new PageSwitch(next, prev);

  const pages = Array.from(document.querySelectorAll('[data-page]'));

  pages.forEach((page) => {
    if (page.dataset.page !== '1') {
      page.style.display = 'none'
    }
  })

  if (next && prev) {
    next.addEventListener('click', () => {
      pageSwitch.next();
    });

    prev.addEventListener('click', () => {
      pageSwitch.previous();
    });
  }
}
